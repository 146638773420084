@import (reference) "~Stylesheets/base.less";
@import "./typography";

.CobaltRadioGroup__option {
  line-height: 20px;
  margin: 8px;
  cursor: pointer;

  &--inline {
    display: inline-block;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  input {
    float: left;
    margin-right: 8px;
    cursor: pointer;
  }

  label {
    .label();
    padding: 0;
    margin-bottom: 0;
    cursor: pointer;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
    input,
    label {
      cursor: not-allowed;
    }
  }
}
